<template>
  <div class="todo-date"><strong>日期：</strong>{{ aData }}</div>
</template>

<script>
import moment from 'moment';

export default {
  name: "MyData",
  data() {
    return {
      aData: moment(new Date()).format('YYYY-MM-DD'),
    }
  }
}
</script>

<style scoped>
.todo-date {
  margin-bottom: 10px;
  text-align: center;
}
</style>
